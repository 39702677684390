 /* Navigation Bar */



         .nav{
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 7rem; width: 100%;
            position: fixed;
            top: 0;
            z-index: 10;   
            transition:  0.5s linear;
          
            padding-bottom: 1rem;
        }
        .navActiveLight{
            display: flex;
            justify-content: space-between;
            align-items:center;
            height: 7rem; width: 100%;
            position: fixed;
            top: 0;
            z-index: 10;
            transition:  0.5s linear;
          background-color: rgba(0,0,255,0.2);
            padding-bottom: .8rem;
        }
     
        .Hactive{
          
          transition:  0.5s linear;
          font-size: 1.5rem;
        }
        .H{
      
          transition:  0.5s linear;
          font-size: 2rem;
        }
        .Logo{
            width: 12rem;
            transition:  0.5s linear;
           
        }

        .LogoActive{
          width: 12rem;
          transition:  0.5s linear;
          
      }

       
        .list{
            list-style: none;
            display: flex;
            justify-content: space-between !important;
            /* background: aqua; */
            width: 45rem;
            text-decoration: none;
            text-align: center;
            /* font-size: 1rem !important ; */
            /* margin-right: 10rem; */
        }
 

          
          .list * {
            box-sizing: border-box;
            transition: all .35s ease;
            font-size: 0.95rem;
            /* font-weight: bolder; */
          }
          
          .list li {
            display: inline-block;
            list-style: outside none none;
            margin: .5em 1em;
            padding: 0;
          }
          
         
          .list a {
            padding: .5em .8em;
            color: #000 !important;
            position: relative;
            text-decoration: none;
           
            font-weight: 550;
          }
          
          .list a::before,
          .list a::after {
            content: '';
            height: 14px;
            width: 14px;
            position: absolute;
            transition: all .35s ease;
            opacity: 0;
          }
          
          .list a::before {
            content: '';
            right: 0;
            top: 0;
            /* border-top: 3px solid rgba(2, 169, 92, 1);
            border-right: 3px solid rgba(2, 169, 92, 1); */
            transform: translate(-100%, 50%);
          }
          
          .list a:after {
            content: '';
            left: 0;
            bottom: 0;
            /* border-bottom: 3px solid rgba(2, 169, 92, 1);
            border-left: 3px solid rgba(2, 169, 92, 1); */
            transform: translate(100%, -50%)
          }
          
          .list a:hover:before,
          .list a:hover:after{
            transform: translate(0,0);
            opacity: 1;
          }
          
          .list a:hover {
            color: #8BC7FE !important;
          }
          

        
        .container{
            display: none;
        }
        .hamburger{
            display: none;
        }


    

        @media(max-width: 1200px){
            .nav{
                justify-content: space-between;
                /* padding-left: 2rem; */
             
                position: fixed;
                align-items: center;
                flex-direction: row;
                width: 100%;
            }
            .navActiveLight{
                justify-content: space-between;
                /* padding-left: 2rem; */
          
                position: fixed;
                z-index: 10;    @media(max-width: 1600px){
          
                  .list a {
                    padding: .5em .8em;
                    color: #000 !important;
                    position: relative;
                    text-decoration: none;
                    /* font-size: 1.2rem; */
                    font-weight: 550;
                  }
        
               
                .list{
                  list-style: none;
                  display: flex;
                  justify-content: space-between;
                  /* background: aqua; */
                 
                  text-decoration: none;
                  /* font-size: 1rem !important ; */
                  margin-right: 3rem;      }
                }
               
                opacity: 1;
                flex-direction: row;
            }
            .navActiveDark{
              justify-content: space-between;
              /* padding-left: 2rem; */
              height: 4rem;
              position: fixed;
              background: rgba(229, 229, 229, 0.9);
              z-index: 10;
              opacity: 1;
              flex-direction: row;
          }
            .none{
                display: none;
            }
            
            .list{
               display: none;
            }    @media(max-width: 1600px){
          
              .list a {
                padding: .5em .8em;
                color: #000 !important;
                position: relative;
                text-decoration: none;
                /* font-size: 1.2rem; */
                font-weight: 550;
              }
    
           
            .list{
              list-style: none;
              display: flex;
              justify-content: space-between;
              /* background: aqua; */
             
              text-decoration: none;
              /* font-size: 1rem !important ; */
              margin-right: 3rem;      }
            }
           
         
        }


        @media(max-width: 768px){
          /* .Logo{
            width: 5rem;
            transition:  0.5s linear;
            margin: 0.8rem 0 0 0;
        }

        .LogoActive{
          width: 5rem;
          transition:  0.5s linear;
          margin: 0.6rem 0 0 0;
          background-color: rgba(249, 232, 202, 0.8);
      } */
  
        }
                               /* Navigartion Bar Ends */