.input{
    background-color: #FFFFFF;  
 backdrop-filter: blur(20px);
 border: none;
 color: #000000;
 padding: 0 0 0 0.1rem;
 font-size: 1.2rem;
}
.input:focus {
    border: none;
    outline: none;
}
.input::placeholder{
    text-align: left;
    color: grey;
}